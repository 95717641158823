exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-debug-benefit-tsx": () => import("./../../../src/pages/debug/benefit.tsx" /* webpackChunkName: "component---src-pages-debug-benefit-tsx" */),
  "component---src-pages-debug-contact-tsx": () => import("./../../../src/pages/debug/contact.tsx" /* webpackChunkName: "component---src-pages-debug-contact-tsx" */),
  "component---src-pages-debug-expertise-tsx": () => import("./../../../src/pages/debug/expertise.tsx" /* webpackChunkName: "component---src-pages-debug-expertise-tsx" */),
  "component---src-pages-debug-hero-tsx": () => import("./../../../src/pages/debug/hero.tsx" /* webpackChunkName: "component---src-pages-debug-hero-tsx" */),
  "component---src-pages-debug-project-tsx": () => import("./../../../src/pages/debug/project.tsx" /* webpackChunkName: "component---src-pages-debug-project-tsx" */),
  "component---src-pages-debug-skill-tsx": () => import("./../../../src/pages/debug/skill.tsx" /* webpackChunkName: "component---src-pages-debug-skill-tsx" */),
  "component---src-pages-debug-work-tsx": () => import("./../../../src/pages/debug/work.tsx" /* webpackChunkName: "component---src-pages-debug-work-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-layout-tsx": () => import("./../../../src/pages/layout.tsx" /* webpackChunkName: "component---src-pages-layout-tsx" */)
}

